module.exports = {
        email: 'kyle.mcsweeney3@gmail.com',
        github: 'https://github.com/KyleMcSweeney3/',
        linkedIn: 'https://www.linkedin.com/in/kyle-mcsweeney3/',
        instagram: 'https://www.instagram.com/kyle__mc3/',
        colours: {
            navy: '#0a192f',
            lightNavy: '#112240',
            lightestNavy: '#233554',
            white: "#e6f1ff",
            blue: "#00ABE1",
            slate: "#8892b0",
            lightSlate: "#a8b2d1",
            lightestSlate: "#ccd6f6",
            greenTint: "rgba(92, 216, 255, 0.1)",
            pink: '#f57dff',
        },
        srConfig: (delay = 200, viewFactor = 0.25) => ({
            origin: 'bottom',
            distance: '20px',
            duration: 500,
            delay,
            rotate: { x: 0, y: 0, z: 0 },
            opacity: 0,
            scale: 1,
            easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
            mobile: true,
            reset: false,
            useDelay: 'always',
            viewFactor,
            viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
          }),
}